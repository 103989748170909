<template>
  <div class="main">
    <Jumbotron
    :title="getTitle"
    img="mixer.jpg"
  />
  <div v-if="lang === 'it'" class="container">
    <h5>DISCOGRAFIA</h5>

    <div class="bio">
    1990 Splasc(h)  Records - Transit “Portrait of a Trio” <br>
    (Marcello Noia sassofoni, Valerio Luraghi batteria)

    <hr>
    1990 Splasc(h) Records - Arrigo Cappelletti New Latin Ensemble “Pianure”<br>
    (Arrigo Cappelletti pianoforte, Gianni Coscia fisarmonica, Giulio Visibelli sassofono, Maurizio Dehò violino) 
    <hr>
    1991 Change Art - Moni Ovadia “Oylem Goylem”
    <img src="@/assets/images/copertine/oylem.jpg" alt="Luca Garlaschelli - discografia">
    <hr>
    1992 Splasc(h) Records -Transit  “Polyedros”<br>
    (Marcello Noia-Daniele Cavallanti  sassofoni, Valerio Luraghi batteria)
    <img src="@/assets/images/copertine/transit.jpg" alt="Luca Garlaschelli - discografia">
    <hr>
    1992 Splasc(h) Records - Piero Bassini  “Another Dream”<br>
    (Piero Bassini pianoforte, Massimo Pintori batteria)
    <img src="@/assets/images/copertine/piero-bassini.jpg" alt="Luca Garlaschelli - discografia">
    <hr>
    1994 DMCD - Enzo Rocco  “Accordo”<br>
    (Enzo Rocco chitarra, Massimo Pintori batteria)
    <hr>
    1995 Mingus Production - Antonio Scarano  “Things to change”<br>
    (Antonio Scarano chitarra, Stefano Bagnoli batteria, Bruno De Filippi armonica, Renato Sellani pianoforte)
    <hr>
    1995 Red Record - Piero Bassini  “Intensity”<br>
    (Piero Bassini pianoforte, Massimo Pintori-Ettore Fioravanti batteria)
    <hr>
    1995 Moni Ovadia Maria Colegni “Sore Lume”<br>
    (Maria Colegni voce, Vladimir Denissenkov fisarmonica, Janos Hasur violino)
    <hr>
    1996 Old Magic Music - Dario Cellamaro “Sweet Love”<br>
    (Dario Cellamaro batteria, Luca Begonia trombone, Claudio Capurro sassofono, Paolo Silvestri pianoforte)
    <hr>
    1996 Splasc(h) Records - Piero Bassini  “1T 6 V”<br>
    (Piero Bassini pianoforte, Ettore Fioravanti batteria)
    <hr>
    1997 Splasc(h) Records - Piero Bassini  “Portrait with in”<br>
    (Piero Bassini pianoforte, Ettore Fioravanti batteria)
    <hr>
    1997 Altri Suoni - Giulio Granati  “Live at the Principe Leopoldo”<br>
    (Giulio Granati pianoforte, Franchino D’Auria batteria)
    <hr>
    1998 Original Fonola - Lorenzo Cuneo  “What’s new in fifty”<br>
    (Franco D’Andrea-Ivano Borgazzi pianoforte, Michael Rosen sassofono)
    <hr>
    1998  Musica Oggi - Gaetano Liguori  “Atelier musicale del XX secolo”		<br>		     
    (Gaetano Liguori pianoforte, Ferdinando Faraò batteria)
    <hr>
    1999  Splasc(h) Records - Piero Bassini  “Minor contest”<br>
    (Piero Bassini pianoforte, Roberto Gatto batteria)
    <hr>
    1999  Splasc(h) Records - D’Auria-Aliffi Quartet  “I ¾ stanno sotto”<br>
    (Maurizio Aliffi chitarra, Franchino D’Auria batteria, Gabriele Mirabassi clarinetto, Mario Arcari oboe, Simone Mauri clarinetto basso)	
        <hr>	
    1999  Audiar - Luca Garlaschelli Musikorchestra “Don’t forget…”<br>
    (Musikorchestra+Moni Ovadia, Tiziana Ghiglioni, Vladimir Denissenkov)
    <hr>
    2000  Audiar - Luca Garlaschelli Musikorchestra  “Escuela de Oficios”
    <hr>
    2000  Splasc(h) Records - Gaetano Liguori  “Ronin”<br>
    (Gaetano Liguori pianoforte, Guido Mazzon tromba, Carlo Actis Dato sassofoni, Martin Mayers corno)
    <hr>
    2000  Red House - Piero Bassini Trio “La storia di Piero”<br>
    (Piero Bassini  pianoforte, Luca Mezzadri batteria)
    <hr>
    2001  Saar Records - Roberto Durkovic “Inciampo nel mare”
    <hr>
    2001  Face Record - Luca Garlaschelli Musikorchestra  “The sound of dream”
    <hr>
    2001 Musica Jazz Red Records - Piero Bassini Trio  “Un filo rosso nel jazz”<br>
    (Piero Bassini pianoforte, Ettore Fioravanti batteria)
    <hr>
    2002 MAP - Morenghi-Borgazzi Group  “Sound and images”<br>
    (Claudio Morenghi sassofoni, Ivano Borgazzi pianoforte, Andrea Tofanelli tromba, Massimo Manzi batteria)
    <hr>
    2002 L’Artistica - Moni Ovadia “Il Violinista sul Tetto”
    <hr>
    2003 Materiali Musicali de “Il Manifesto” - Gaetano Liguori Trio “Il Comandante” <br>
    (Gaetano Liguori pianoforte, Luca Garlaschelli contrabbasso, Massimo Pintori  batteria)
    <hr>
    2004 Radio Coop - Luca Garlaschelli Musikorchestra  “Salam Alaykum”
    <hr>
    2005 Promomusic - Moni Ovadia  “Kavanàh”
    <hr>
    2005 Mantova Musica Festival - Gaetano Liguori Trio
    <hr>
    2005 Einaudi Stile Libero/DVD - Moni Ovadia “Oylem Goylem”
    <hr>
    2006 Promomusic/DVD - Moni Ovadia “Es iz Amerike!”
    <hr>
    2007  Radio Coop - Luca Garlaschelli Musikorchestra   “Mai tardi” <br>
    (Giovanni Falzone tromba, Simone Mauri-Renata Vinci clarinetti, Enzo Rocco chitarra, Luca Garlaschelli contrabbasso, Ferdinando Faraò percussioni, Elena Arcuri voce.  Voci recitanti di Moni Ovadia, Ascanio Celestini, Bebo Storti, Lucia Vasini, Don Andrea Gallo, Ruggero Cara, Elena Sardi)
    <img src="@/assets/images/copertine/mai-tardi.jpg" alt="Luca Garlaschelli - discografia">
    <hr>
    2008 Ass. Culturale Teatro Tribiano - Luca Garlaschelli Musikorchestra	 “Storia di una ragazza di Varsavia”
    <hr>
    2008 Promomusic/DVD - Moni Ovadia  “La bella utopia”
    <hr>
    2009 Promomusic-RAI Trade - Moni Ovadia-Felice Cappa “Binario 21”
    <hr>

    2010 SNJ - Luca Garlaschelli  Musikorchestra  “Mingus in strings” Vol. 1<br>
    (Luca Garlaschelli contrabbasso, Emanuele Parrini-Mariella Sanvito violino, Paolo Botti viola e banjo, Eliana Gintoli violoncello, Tiziana Ghiglioni voce, Paolo Tomelleri clarinetto, Davide Corini pianoforte)
    <img src="@/assets/images/copertine/musikorchestra.jpg" alt="Luca Garlaschelli - discografia">
    <hr>
    2011 Sensible Record - Rhapsodija Trio “Chant de joie et de regret”<br>
    (Maurizio Dehò violino, Luigi Maione chitarra, Nadio Marenco fisarmonica, Luca Garlaschelli contrabbasso)
    <hr>
    2012 SNJ - “Duology”
    (Claudio Fasoli sassofoni, Luca Garlaschelli contrabbasso)
    <img src="@/assets/images/copertine/fasoli.jpg" alt="Luca Garlaschelli - discografia">
    <hr>
    2012 Music Center - “Verdi in Jazz”<br>
    (Paolo Tomelleri clarinetto, Rudy Migliardi trombone, Davide Corini pianoforte, Luca Garlaschelli contrabbasso, Tommy Bradascio batteria)                       
    <hr>
    2013 Stefano Sernagiotto Trio  “Tenor Exit”<br>
    (Stefano Sernagiotto sassofono tenore, Luca Garlaschelli contrabbasso, Massimo Pintori batteria) 
    <hr>
    2014 Music Center - “Tammuriata nera, Napoli canzoni e jazz”<br>
    (Paola Quagliata-Massimo Guerrini voce, Davide Corini pianoforte, Massimo Minardi chitarra, Luca Garlaschelli arrangiamenti e contrabbasso, Massimo Pintori batteria)
    <img src="@/assets/images/copertine/tammuriata.jpg" alt="Luca Garlaschelli - discografia">
    <hr>
    2015 Flipper Edizioni - Xilo Ensemble  “Klezmer in Paris”<br>
    (Stefano Corradi clarinetto, G. Pietro Marazza fisarmonica,  Luca Garlaschelli contrabbasso)
    <img src="@/assets/images/copertine/xilo.jpg" alt="Luca Garlaschelli - discografia">
    <hr>
    2016 Piacenza Musica SRL - “Jazzin’ Around Baroque”<br>
    (Paola Quagliata voce, Davide Corini pianoforte, Luca Garlaschelli contrabbasso e arrangiamenti, Rudy Royston batteria)
    <hr>
    2016 Tranquilo SAS - “Don Quixote”<br>
    (Stefano Corradi clarinetto, Dora Scapolatempore arpa, Luca Garlaschelli contrabbasso, Tiziano Tononi batteria)
    <img src="@/assets/images/copertine/don-quixote.jpg" alt="Luca Garlaschelli - discografia">
    <hr>
    2017 Losen Records -  Dino Massa Quartet “Suite pour le piano for jazz Quartet”<br>
    (Dino Massa pianoforte, Nicola Pisani sassofono soprano e baritono, Luca Garlaschelli contrabbasso, Alessandro Rossi batteria)
    <img src="@/assets/images/copertine/dino-massa.jpg" alt="Luca Garlaschelli - discografia">
    <hr>
    2018 Jude Lindy and the hot swing Propellers “Shine!”  <br>                                          
    (Jude Lindy voce, Simone Dionigi Pala sassofono tenore e soprano, Maurizio Leoni sassofono baritono, Marco Cortese chitarra, Luca Garlaschelli contrabbasso, Alessio Pacifico batteria)
    <img src="@/assets/images/copertine/jude-lindy.jpg" alt="Luca Garlaschelli - discografia">
    <hr>
    2019 Dodicilune - Francesco Caligiuri Quintet  “Renaissance”<br>
    (Francesco Caligiuri sassofono baritono-clarinetto basso-flauto, Nicola Pisani sassofono soprano, Michel Godard tuba-serpentone, Luca Garlaschelli contrabbasso, Francesco Montebello batteria)
    <img src="@/assets/images/copertine/francesco-caligiuri.jpg" alt="Luca Garlaschelli - discografia">
    <hr>
    2021 Bardaro Clarinettes Ensemble “Rapsodia per contrabbasso e coro di clarinetti”<br>
    (Alessandro Eusebio direzione-arrangiamento-clarinetto soprano, Milo Sbragion clarinetto soprano, Giovanni Longo clarinetto soprano, Luca Petitto clarinetto basso, Carlo Nava clarinetto basso, Andrea Tabasso clarinetto soprano, Dario Gargiulo clarinetto soprano, Alexander Utikin clarinetto soprano, Francesco Gargiulo clarinetto soprano, Giordano Vergani clarinetto soprano, Luca Garlaschelli contrabbasso. Guests: Paolo Tomelleri clarinetto, Mauro Negri clarinetto)


    </div>

  </div>

  <div v-else class="container">
    <h5>DISCOGRAPHY</h5>

    <div class="bio">
    1990 Splasc(h)  Records - Transit “Portrait of a Trio” <br>
    ((Marcello Noia saxophones, Valerio Luraghi drums)

    <hr>
    1990 Splasc(h) Records - Arrigo Cappelletti New Latin Ensemble “Pianure”<br>
    (Arrigo Cappelletti piano, Gianni Coscia accordion, Giulio Visibelli saxophone, Maurizio Dehò violin) 
    <hr>
    1991 Change Art - Moni Ovadia “Oylem Goylem”
    <img src="@/assets/images/copertine/oylem.jpg" alt="Luca Garlaschelli - discografia">
    <hr>
    1992 Splasc(h) Records -Transit  “Polyedros”<br>
    (Marcello Noia-Daniele Cavallanti  saxophone, Valerio Luraghi drums)
    <img src="@/assets/images/copertine/transit.jpg" alt="Luca Garlaschelli - discografia">
    <hr>
    1992 Splasc(h) Records - Piero Bassini  “Another Dream”<br>
    (Piero Bassini piano, Massimo Pintori drums)
    <img src="@/assets/images/copertine/piero-bassini.jpg" alt="Luca Garlaschelli - discografia">
    <hr>
    1994 DMCD - Enzo Rocco  “Accordo”<br>
    (Enzo Rocco chitarra, Massimo Pintori drums)
    <hr>
    1995 Mingus Production - Antonio Scarano  “Things to change”<br>
    (Antonio Scarano chitarra, Stefano Bagnoli drums, Bruno De Filippi armonica, Renato Sellani piano)
    <hr>
    1995 Red Record - Piero Bassini  “Intensity”<br>
    (Piero Bassini piano, Massimo Pintori-Ettore Fioravanti drums)
    <hr>
    1995 Moni Ovadia Maria Colegni “Sore Lume”<br>
    (Maria Colegni voice, Vladimir Denissenkov accordion, Janos Hasur violin)
    <hr>
    1996 Old Magic Music - Dario Cellamaro “Sweet Love”<br>
    (Dario Cellamaro drums, Luca Begonia trombone, Claudio Capurro saxophone, Paolo Silvestri piano)
    <hr>
    1996 Splasc(h) Records - Piero Bassini  “1T 6 V”<br>
    (Piero Bassini piano, Ettore Fioravanti drums)
    <hr>
    1997 Splasc(h) Records - Piero Bassini  “Portrait with in”<br>
    (Piero Bassini piano, Ettore Fioravanti drums)
    <hr>
    1997 Altri Suoni - Giulio Granati  “Live at the Principe Leopoldo”<br>
    (Giulio Granati piano, Franchino D’Auria drums)
    <hr>
    1998 Original Fonola - Lorenzo Cuneo  “What’s new in fifty”<br>
    (Franco D’Andrea-Ivano Borgazzi piano, Michael Rosen saxophone)
    <hr>
    1998  Musica Oggi - Gaetano Liguori  “Atelier musicale del XX secolo”		<br>		     
    (Gaetano Liguori piano, Ferdinando Faraò drums)
    <hr>
    1999  Splasc(h) Records - Piero Bassini  “Minor contest”<br>
    (Piero Bassini piano, Roberto Gatto drums)
    <hr>
    1999  Splasc(h) Records - D’Auria-Aliffi Quartet  “I ¾ stanno sotto”<br>
    (Maurizio Aliffi guitar, Franchino D’Auria drums, Gabriele Mirabassi clarinet, Mario Arcari oboe, Simone Mauri bass clarinet)	
        <hr>	
    1999  Audiar - Luca Garlaschelli Musikorchestra “Don’t forget…”<br>
    (Musikorchestra+Moni Ovadia, Tiziana Ghiglioni, Vladimir Denissenkov)
    <hr>
    2000  Audiar - Luca Garlaschelli Musikorchestra  “Escuela de Oficios”
    <hr>
    2000  Splasc(h) Records - Gaetano Liguori  “Ronin”<br>
    (Gaetano Liguori piano, Guido Mazzon trumpet, Carlo Actis Dato saxophone, Martin Mayers franch horn)
    <hr>
    2000  Red House - Piero Bassini Trio “La storia di Piero”<br>
    (Piero Bassini  piano, Luca Mezzadri drums)
    <hr>
    2001  Saar Records - Roberto Durkovic “Inciampo nel mare”
    <hr>
    2001  Face Record - Luca Garlaschelli Musikorchestra  “The sound of dream”
    <hr>
    2001 Musica Jazz Red Records - Piero Bassini Trio  “Un filo rosso nel jazz”<br>
    (Piero Bassini piano, Ettore Fioravanti drums)
    <hr>
    2002 MAP - Morenghi-Borgazzi Group  “Sound and images”<br>
    (Claudio Morenghi saxophone, Ivano Borgazzi piano, Andrea Tofanelli tromba, Massimo Manzi drums)
    <hr>
    2002 L’Artistica - Moni Ovadia “Il Violinista sul Tetto”
    <hr>
    2003 Materiali Musicali de “Il Manifesto” - Gaetano Liguori Trio “Il Comandante” <br>
    (Gaetano Liguori piano, Luca Garlaschelli double bass, Massimo Pintori  drums)
    <hr>
    2004 Radio Coop - Luca Garlaschelli Musikorchestra  “Salam Alaykum”
    <hr>
    2005 Promomusic - Moni Ovadia  “Kavanàh”
    <hr>
    2005 Mantova Musica Festival - Gaetano Liguori Trio
    <hr>
    2005 Einaudi Stile Libero/DVD - Moni Ovadia “Oylem Goylem”
    <hr>
    2006 Promomusic/DVD - Moni Ovadia “Es iz Amerike!”
    <hr>
    2007  Radio Coop - Luca Garlaschelli Musikorchestra   “Mai tardi” <br>
    (Giovanni Falzone tromba, Simone Mauri-Renata Vinci clarinetti, Enzo Rocco chitarra, Luca Garlaschelli double bass, Ferdinando Faraò percussioni, Elena Arcuri voce.  Voci recitanti di Moni Ovadia, Ascanio Celestini, Bebo Storti, Lucia Vasini, Don Andrea Gallo, Ruggero Cara, Elena Sardi)
    <img src="@/assets/images/copertine/mai-tardi.jpg" alt="Luca Garlaschelli - discografia">
    <hr>
    2008 Ass. Culturale Teatro Tribiano - Luca Garlaschelli Musikorchestra	 “Storia di una ragazza di Varsavia”
    <hr>
    2008 Promomusic/DVD - Moni Ovadia  “La bella utopia”
    <hr>
    2009 Promomusic-RAI Trade - Moni Ovadia-Felice Cappa “Binario 21”
    <hr>

    2010 SNJ - Luca Garlaschelli  Musikorchestra  “Mingus in strings” Vol. 1<br>
    (Luca Garlaschelli double bass, Emanuele Parrini-Mariella Sanvito violin, Paolo Botti viola e banjo, Eliana Gintoli violoncello, Tiziana Ghiglioni voce, Paolo Tomelleri clarinet, Davide Corini piano)
    <img src="@/assets/images/copertine/musikorchestra.jpg" alt="Luca Garlaschelli - discografia">
    <hr>
    2011 Sensible Record - Rhapsodija Trio “Chant de joie et de regret”<br>
    (Maurizio Dehò violin, Luigi Maione guitar, Nadio Marenco accordion, Luca Garlaschelli double bass)
    <hr>
    2012 SNJ - “Duology”
    (Claudio Fasoli saxophone, Luca Garlaschelli double bass)
    <img src="@/assets/images/copertine/fasoli.jpg" alt="Luca Garlaschelli - discografia">
    <hr>
    2012 Music Center - “Verdi in Jazz”<br>
    (Paolo Tomelleri clarinet, Rudy Migliardi trombone, Davide Corini piano, Luca Garlaschelli double bass, Tommy Bradascio drums)                       
    <hr>
    2013 Stefano Sernagiotto Trio  “Tenor Exit”<br>
    (Stefano Sernagiotto tenor saxophone , Luca Garlaschelli double bass, Massimo Pintori drums) 
    <hr>
    2014 Music Center - “Tammuriata nera, Napoli canzoni e jazz”<br>
    (Paola Quagliata-Massimo Guerrini voce, Davide Corini piano, Massimo Minardi chitarra, Luca Garlaschelli arrangiamenti e double bass, Massimo Pintori drums)
    <img src="@/assets/images/copertine/tammuriata.jpg" alt="Luca Garlaschelli - discografia">
    <hr>
    2015 Flipper Edizioni - Xilo Ensemble  “Klezmer in Paris”<br>
    (Stefano Corradi clarinetto, G. Pietro Marazza accordion,  Luca Garlaschelli double bass)
    <img src="@/assets/images/copertine/xilo.jpg" alt="Luca Garlaschelli - discografia">
    <hr>
    2016 Piacenza Musica SRL - “Jazzin’ Around Baroque”<br>
    (Paola Quagliata voce, Davide Corini piano, Luca Garlaschelli double bass e arrangiamenti, Rudy Royston drums)
    <hr>
    2016 Tranquilo SAS - “Don Quixote”<br>
    (Stefano Corradi clarinetto, Dora Scapolatempore harp, Luca Garlaschelli double bass, Tiziano Tononi drums)
    <img src="@/assets/images/copertine/don-quixote.jpg" alt="Luca Garlaschelli - discografia">
    <hr>
    2017 Losen Records -  Dino Massa Quartet “Suite pour le piano for jazz Quartet”<br>
    (Dino Massa piano, Nicola Pisani  soprano saxophone and baritono, Luca Garlaschelli double bass, Alessandro Rossi drums)
    <img src="@/assets/images/copertine/dino-massa.jpg" alt="Luca Garlaschelli - discografia">
    <hr>
    2018 Jude Lindy and the hot swing Propellers “Shine!”  <br>                                          
    (Jude Lindy voce, Simone Dionigi Pala tenor saxophone  e soprano, Maurizio Leoni baritono saxophone, Marco Cortese chitarra, Luca Garlaschelli double bass, Alessio Pacifico drums)
    <img src="@/assets/images/copertine/jude-lindy.jpg" alt="Luca Garlaschelli - discografia">
    <hr>
    2019 Dodicilune - Francesco Caligiuri Quintet  “Renaissance”<br>
    (Francesco Caligiuri baritono saxophone-clarinetto basso-flauto, Nicola Pisani  soprano saxophone, Michel Godard tuba-serpentone, Luca Garlaschelli double bass, Francesco Montebello drums)
    <img src="@/assets/images/copertine/francesco-caligiuri.jpg" alt="Luca Garlaschelli - discografia">
    <hr>
    2021 Bardaro Clarinettes Ensemble “Rapsodia per double bass e coro di clarinetti”<br>
    (Alessandro Eusebio conductor-arranger-soprano clarinet, Milo Sbragion soprano clarinet, Giovanni Longo soprano clarinet, Luca Petitto bass clarinet, Carlo Nava bass clarinet, Andrea Tabasso soprano clarinet, Dario Gargiulo soprano clarinet, Alexander Utikin clarinetto soprano, Francesco Gargiulo soprano clarinet, Giordano Vergani  soprano clarinet , Luca Garlaschelli double bass. Guests: Paolo Tomelleri clarinet, Mauro Negri clarinet)


    </div>

  </div>



  </div>
</template>

<script>
import Jumbotron from '@/components/Jumbotron';
export default {
  name: 'Discografia',
  components:{
    Jumbotron
  },
  props:{
    lang:String
  },
  computed:{
    getTitle(){
      if(this.lang === 'it'){
        return 'Discografia'
      }
      return 'Discography'
    }
  }
}
</script>

<style lang="scss" scoped>
.main{
   margin: 45px 0;
}
.bio{
  text-align: justify;
  margin-top:20px
}
hr{
  color: white;
}
img{
  width: 250px;
  display: block;
}
</style>